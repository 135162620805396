<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("ESTIMATE_VEHICLES.MAKE_VEHICLE_ESTIMATION") }}
            </h3>
          </div>
          <!--<div class="col-6 text-right">
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PACKAGES)"
              :objectType="'packages'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>-->
        </div>
      </div>

      <div
        class="page-wrapper-table"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS)
        "
      >
        <view-estimate-component />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ViewEstimateComponent from "./components/ViewEstimateComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    NotificationSubscription,
    ViewEstimateComponent,
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},

  data() {
    return {};
  },

  methods: {},
};
</script>
